/* eslint-disable */
import './ToolBar.css';
import React  from 'react';
import { IonCol, IonFooter, IonGrid, IonHeader, IonRow, IonTitle, IonToolbar } from '@ionic/react';

const logo = require('../assets/hrslogo.png');
function Toolbar() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='toolbar-row'>
                <IonCol class='toolbar-col'>
                  <a href='/'><img id='toolbar-logo' src={logo}/></a>
                </IonCol>
                <IonCol class='toolbar-col'>
                  <IonTitle id='toolbar-title'>HRS Logistics Order Portal - Plus Tier</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
          
        </IonHeader>
        <IonFooter>
        <IonToolbar>
          <a href='https://www.healthrecoverysolutions.com/privacy-policy' rel="noreferrer" target='_blank'>Privacy Policy</a>
        </IonToolbar>
      </IonFooter>
      </>
    );
  }
  export default Toolbar;
  